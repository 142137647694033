import { RouteComponentProps } from "react-router";
import React from "react";
import Container from "react-bootstrap/Container";
import { HashLink } from 'react-router-hash-link';
import {table1} from "../constants/table1";
import {table5} from "../constants/table5";
import TableAdvanced from "../components/TableAdvanced";
import { init } from "emailjs-com";
import slideshow6 from "../img/slideshow_2/6239_Bhend_Thundorf_Aussen_K12_180321.jpg";
init("user_1YkWxnIKqu5sDyPZXDVFp");

interface MatchParams { }
interface Props extends RouteComponentProps<MatchParams> {}
interface State { }

export default class Buy extends React.Component<Props, State> {


	scrollWidthOffset(el: any) {
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = -110;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
	}

	render() {
		return (
			<>
				<div
					style={{
						backgroundPosition: "center",
						height: "400px",
						backgroundImage: "url(" + slideshow6 + ")",
						backgroundSize: "cover",
						marginTop: "100px",
						marginBottom: "30px",
					}}
				></div>
				<code className="component-info">{this.constructor.name}</code>
				<Container>
					<main>
						<h1>Wohnungen</h1>

						<HashLink smooth to="/buy#anchor1" scroll={this.scrollWidthOffset}>Eigentumswohnungen</HashLink><br />
						<HashLink smooth to="/buy#anchor2" scroll={this.scrollWidthOffset}>Einfamilienhaus</HashLink><br />
						<HashLink smooth to="/buy#anchor3" scroll={this.scrollWidthOffset}>Parkplätze</HashLink><br />

						<hr />
						<h2 id="anchor1">Eigentumswohnungen</h2>
						<TableAdvanced input={table1} />

						<hr />
						<h2 id="anchor2">Einfamilienhaus zum Verkauf</h2>
						<TableAdvanced input={table5} />

						<hr />
						<h2 id="anchor3">Parkplätze</h2>
						<p><strong>14 Parkplätze</strong> zum Preis von <strong>35'000.00 CHF pro Parkplatz</strong> stehen zum Verkauf</p>

					</main>
				</Container>
			</>
		);
	}
}
