import { RouteComponentProps } from "react-router";
import React from "react";
import Container from "react-bootstrap/Container";
import { HashLink } from 'react-router-hash-link';
import {table2} from "../constants/table2";
import {table3} from "../constants/table3";
import {table4} from "../constants/table4";
import TableAdvanced from "../components/TableAdvanced";
import { init } from "emailjs-com";
import slideshow6 from "../img/slideshow_2/6239_Bhend_Thundorf_Aussen_K12_180321.jpg";
init("user_1YkWxnIKqu5sDyPZXDVFp");

interface MatchParams {}
interface Props extends RouteComponentProps<MatchParams> {}
interface State {}

export default class Rent extends React.Component<Props, State> {


	scrollWidthOffset(el: any) {
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = -110;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
	}

	render() {
		return (
			<>
				<div
					style={{
						backgroundPosition: "center",
						height: "400px",
						backgroundImage: "url(" + slideshow6 + ")",
						backgroundSize: "cover",
						marginTop: "100px",
						marginBottom: "30px",
					}}
				></div>
				<code className="component-info">{this.constructor.name}</code>
				<Container>
					<main>
						<h1>Wohnungen</h1>

						<HashLink smooth to="/rent#anchor1" scroll={this.scrollWidthOffset}>Mietwohnungen</HashLink><br />
						<HashLink smooth to="/rent#anchor2" scroll={this.scrollWidthOffset}>Parkplätze</HashLink><br />

						<hr />
						<h2 id="anchor1">Mietwohnungen</h2>
						<TableAdvanced input={table2} />
						<TableAdvanced input={table3} />
						<TableAdvanced input={table4} />

						<hr />
						<h2 id="anchor2">Parkplätze</h2>
						<p><strong>38 Parkplätze</strong> können zum Preis von <strong>125.00 CHF pro Monat und Parkplatz</strong> gemietet werden</p>

					</main>
				</Container>
			</>
		);
	}
}
