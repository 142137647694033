import Footer from "./components/Footer";
import ThanksView from "./views/ThanksView";
import Navigation from "./components/Navigation";
import Rent from "./views/Rent";
import Buy from "./views/Buy";
import Welcome from "./views/Welcome";
import Contact from "./views/Contact";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import ErrorView from "./views/ErrorView";

function App() {
	return (
		<Router>
			<div id="page-container">
				<div id="content-wrap">
					<Navigation />
					<Switch>
						<Route exact path="/" component={Welcome} />
						<Route exact path="/rent" component={Rent} />
						<Route exact path="/buy" component={Buy} />
						<Route exact path="/contact" component={Contact} />
						<Route exact path="/thankyou" component={ThanksView} />
						<Route component={ErrorView} />
					</Switch>
					<Footer />
				</div>
			</div>
		</Router>
	);
}

export default App;
