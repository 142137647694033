export const table3 = {
	title: "Hauptstrasse 11a",
	alignInfo: [
		"center",
		"left",
		"right",
		"right",
		"right",
		"right",
		"right",
		"right",
		"right",
		"center",
	],
	data: [
		{
			content: [
				"Wohnung",
				"Geschoss/Lage",
				"Zimmer",
				"m²",
				"Sitzplatz",
				"Loggia",
				"WK/Keller",
				"Nettomietzins",
				"Nebenkosten",
				"Status",
			],
			link: "",
		},
		{
			content: ["1", "EG/Nord", "3.5", "91", "20", "15", "18", "1'800.00", "180.00", "vermietet"],
			link: "11a_EG_150.pdf",
		},
		{
			content: ["2", "EG/Süd", "4.5", "105", "20", "15", "24", "2'000.00", "210.00", "vermietet"],
			link: "11a_EG_150.pdf",
		},
		{
			content: ["3", "1. OG/Nord", "3.5", "91", "", "15", "18", "1'750.00", "180.00", ""],
			link: "11a_OG_150.pdf",
		},
		{
			content: ["4", "1. OG/Süd", "4.5", "105", "", "15", "22", "1'950.00", "210.00", ""],
			link: "11a_OG_150.pdf",
		},
		{
			content: ["5", "DG/Nord", "4.5", "133", "", "21", "22", "2'250.00", "270.00", ""],
			link: "11a_DG_150.pdf",
		},
		{
			content: ["6", "DG/Süd", "5.5", "147", "", "22", "24", "2'350.00", "300.00", "vermietet"],
			link: "11a_DG_150.pdf",
		},
	],
};
