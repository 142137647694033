export const table5 = {
	title: "Kirchbergstrasse 4b",
	alignInfo: [
		"center",
		"right",
		"right",
		"right",
		"right",
		"right",
		"right",
		"center",
	],
	data: [
		{
			content: [
				"Zimmer",
				"m²",
				"Sitzplatz",
				"Loggia",
				"WK/Keller",
				"Grundstücksfläche",
				"Preis (inkl. 2 PP)",
				"Status",
			],
			link: "",
		},
		{ content: ["6.5", "161", "15", "13", "108", "380", "1'100'000.00", "reserviert"], link: "4b_EG_150.pdf" },
	],
};
