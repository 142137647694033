export const table2 = {
	title: "Kirchbergstrasse 4a",
	alignInfo: [
		"center",
		"left",
		"right",
		"right",
		"right",
		"right",
		"right",
		"right",
		"right",
		"center",
	],
	data: [
		{
			content: [
				"Wohnung",
				"Geschoss/Lage",
				"Zimmer",
				"m²",
				"Sitzplatz",
				"Loggia",
				"WK/Keller",
				"Nettomietzins",
				"Nebenkosten",
				"Status",
			],
			link: "",
		},

		{
			content: ["1", "EG/West", "3.5", "91", "20", "15", "18", "1'800.00", "180.00", "vermietet" ],
			link: "4a_EG_150.pdf",
		},
		{
			content: ["2", "EG/Ost", "4.5", "105", "20", "15", "22", "2'000.00", "210.00", "" ],
			link: "4a_EG_150.pdf",
		},
		{
			content: ["3", "1. OG/West", "3.5", "91", "", "15", "18", "1'750.00", "180.00", ""],
			link: "4a_DG_150.pdf",
		},
		{
			content: ["4", "1. OG/Ost", "4.5", "105", "", "15", "22", "1'950.00", "210.00", ""],
			link: "4a_OG_150.pdf",
		},
		{
			content: ["5", "DG/West", "4.5", "133", "", "21", "22", "2'250.00", "270.00", "" ],
			link: "4a_DG_150.pdf",
		},
		{
			content: ["6", "DG/Ost", "5.5", "147", "", "22", "36", "2'350.00", "300.00", ""],
			link: "4a_DG_150.pdf",
		},
	],
};
