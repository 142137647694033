import { RouteComponentProps } from "react-router";
import React from "react";
import Container from "react-bootstrap/Container";
import emailjs from "emailjs-com";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { init } from "emailjs-com";
import slideshow6 from "../img/slideshow_2/6239_Bhend_Thundorf_Innen_EG_K03_160321.jpg";
init("user_1YkWxnIKqu5sDyPZXDVFp");

interface MatchParams {
	bla: string;
}
interface Props extends RouteComponentProps<MatchParams> {}
interface State {
	krass: number;
}

export default class Contact extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			krass: 5,
		};
	}

	sendEmail(e: any) {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_p5djrrj",
				"template_asmmte9",
				e.target,
				"user_1YkWxnIKqu5sDyPZXDVFp"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		window.location.href = "#/thankyou";
	}

	render() {
		return (
			<>
				<div
					style={{
						backgroundPosition: "center",
						height: "400px",
						backgroundImage: "url(" + slideshow6 + ")",
						backgroundSize: "cover",
						marginTop: "100px",
						marginBottom: "30px",
					}}
				></div>
				<code className="component-info">{this.constructor.name}</code>
				<Container>
					<main>
						<h1>Kontakt</h1>
						<p>
							Wünschen Sie weitere Informationen und Unterlagen
							zum Überbauungsprojekt Sunnedörfli Thundorf? <br />
							Wir freuen uns auf Ihre Kontaktaufnahme.
						</p>

						<Form
							onSubmit={this.sendEmail}
							style={{ marginTop: "20px" }}
						>
							<Form.Group controlId="formBasicEmail">
								<Form.Label>Mein Name:</Form.Label>
								<Form.Control
									type="text"
									name="user_name"
									placeholder="Vorname Name"
								/>
							</Form.Group>

							<Form.Group controlId="formBasicPassword">
								<Form.Label>Meine E-Mail:</Form.Label>
								<Form.Control
									type="email"
									placeholder="name@beispiel.com"
									name="user_email"
								/>
							</Form.Group>
							<Form.Group controlId="formBasicPhone">
								<Form.Label>Meine Telefonnummer:</Form.Label>
								<Form.Control
									type="tel"
									placeholder="079 ..."
									name="user_phone"
								/>
							</Form.Group>
							<Form.Group controlId="exampleForm.ControlTextarea1">
								<Form.Label>Meine Nachricht:</Form.Label>
								<Form.Control
									name="message"
									as="textarea"
									rows={3}
								/>
							</Form.Group>

							<Button
								variant="outline-primary"
								type="submit"
								value="Send"
								style={{ marginTop: "18px" }}
							>
								Senden
							</Button>
						</Form>
					</main>
				</Container>
			</>
		);
	}
}
